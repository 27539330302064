<template>
	<div class="header">
		<img src="../assets/happy/logobanner2.png" alt="" class="header-bg">
		<div class="header-box" @click="home">
			<img src="../assets/happy/star.svg" alt="">
			<div>[  首 頁  ]</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HeaderView',
	components: {
	},
	data(){
		return {
		}
	},
	mounted(){
		
	},
	methods:{
        home(){
			if(this.$route.path.split('/')[1]=='index') return
            this.$router.replace('index');
        }
	}
}
</script>

<style scoped lang="scss">
	.header{
        width: 100%;
        position: relative;
        .header-bg{
            width: 100%;
            vertical-align: middle;
        }
        .header-box{
            position: absolute;
            right: 10px;
            bottom: 2px;
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                width: 23px;
                height: 23px;
                
            }
            div{
                font-size: 18px;
                color: #000000;
            }
        }
    }
</style>