<template>
    <footer>
        <div class="footer-menu">
            <div class="footer-nav">
                
            </div>
        </div>
        <div class="copy_right">
            <p>
                <span>未滿18歲不得購買或兌領彩票</span>
                <span class="text-s">ver.1_0_1_9</span>
            </p>
            <p>版權所有 © 2024 台灣彩票股份有限公司</p>
        </div>
    </footer>
</template>
<script>
export default {
	name: 'FooterView',
	components: {
	},
	data(){
		return {
		}
	},
	mounted(){
		
	}
}
</script>
<style lang="scss" scoped>
.footer{
    background-color: #2f373d;
    color: #fff;
    font-size: 14px;
    z-index: 1;
    position: relative;
      }
        .footer-menu{
            display: flex;
            flex-direction: row;
            margin: auto;
            max-width: 1200px;
            padding: 42px 50px 30px;
            position: relative;
        }
        .copy_right{
            background: calc(70% + 43px) 0/contain no-repeat url('../assets/happy/ui_curve.svg'),linear-gradient(90deg,#efdd00 70%,#007979 0);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 16px 35px;
            p{
                color: #000;
                font-size: 12px;
                line-height: 20px;
                font-family: Noto Sans TC,sans-serif;
                .text-s {
                    color: #efdd00;
                }
            }
            p:last-child {
                color: #fff;
            }
        }

@media only screen and (min-width: 320px) and (max-width: 479px){
    .footer{
        padding: 20px 15px !important;
    }
    .img-box{
        justify-content: center !important;
        width: 100% !important;
        img{
            width: 70px !important;
            height: 35px !important;
            margin: 0 2px !important;
        }
    }
    .bottom-copy{
        line-height: 18px !important;
    }
    .copy_right{
            padding: 15px 20px !important;
            flex-wrap: wrap;
            p:last-child{
                color: #000 !important;
            }
        }
}
</style>